<template>
<div>
    <div class="mt-6 lg:flex lg:justify-between lg:items-center box">
        <div class="cardbg px-2 py-3 rounded boxer mb-6">
            <p class="text-base skeleton skeleton-text"></p>
            <p class="mt-2 text-white text-2xl lg:text-4xl skeleton skeleton-text"></p>
            <p class="svg skeleton mt-4"></p>
        </div>
        <div class="cardbg px-2 py-3 rounded boxer mb-6">
            <p class="text-base skeleton skeleton-text"></p>
            <p class="mt-2 text-white text-2xl lg:text-4xl skeleton skeleton-text"></p>
            <p class="svg skeleton mt-4"></p>
        </div>
        <div class="cardbg px-2 py-3 rounded boxer mb-6">
            <p class="text-base skeleton skeleton-text"></p>
            <p class="mt-2 text-white text-2xl lg:text-4xl skeleton skeleton-text"></p>
            <p class="svg skeleton mt-4"></p>
        </div>
    </div>
    <div class="mt-6 lg:flex lg:justify-between lg:items-center box">
        <div class="cardbg px-2 py-3 rounded boxer mb-6">
            <p class="text-base skeleton skeleton-text"></p>
            <p class="mt-2 text-white text-2xl lg:text-4xl skeleton skeleton-text"></p>
            <p class="svg skeleton mt-4"></p>
        </div>
        <div class="cardbg px-2 py-3 rounded boxer mb-6">
            <p class="text-base skeleton skeleton-text"></p>
            <p class="mt-2 text-white text-2xl lg:text-4xl skeleton skeleton-text"></p>
            <p class="svg skeleton mt-4"></p>
        </div>
        <div class="cardbg px-2 py-3 rounded boxer mb-6">
            <p class="text-base skeleton skeleton-text"></p>
            <p class="mt-2 text-white text-2xl lg:text-4xl skeleton skeleton-text"></p>
            <p class="svg skeleton mt-4"></p>
        </div>
    </div>
</div>
</template>

<script>
export default {

}
</script>

<style scoped>
.cardbg{
  background-color: #27293d;
  color:  #9a9a9a
}
.skeleton {
  opacity: .7;
  animation: skeleton-loading 1s linear infinite alternate;
}
.skeleton-text {
  width: 100%;
  height: .7rem;
  margin-bottom: .25rem;
  border-radius: .125rem;
}
.skeleton-text:nth-child(2) {
  width: 50%;
  height: 1.5rem;
}
@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 70%);
  }

  100% {
    background-color: hsl(200, 20%, 95%);
  }
}
.svg{
  width: 40px;
  height: 40px;
  border-radius: 100%
}
@media only screen and (min-width: 1024px){
  .boxer{
    width: 30%;
    margin: 1.67%
  }
}
</style>